.cmp-image__title {
  @include bold-sm();

  display: block;
  word-break: break-word;
  word-wrap: break-word;
  padding: var(--spacing-md) 0.375rem 0;

  a {
    color: var(--color-primary-500);
    text-decoration: underline;

    &:hover {
      color: var(--base-color);
    }
  }
}

.cmp-image {
  position: relative;
  display: block;
}

.cmp-image__image-copyrights {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgb(255 255 255 / 75%);
  margin: 0 var(--spacing-xs) var(--spacing-xs) 0;
  padding: var(--spacing-xs) var(--spacing-xxs);

  @include regular-sm;
}

.image .cmp-responsive-image.replaced img {
  position: initial;
}

@media #{$mq-1} {
  .cmp-image__image-copyrights {
    margin: 0 var(--spacing-xs) var(--spacing-xs);
  }
}
