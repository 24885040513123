/**
 * Footer - Component
 */

:root {
  /* Manually derived value, to be consumed by other components */
  --cmp-footer-height: 23.1875rem;
}

.cmp-footer {
  color: var(--color-white);
  clear: both;
}

/* Footer - Footer elements */
.cmp-footer .cmp-container {
  max-width: none;
  padding: 0;
}

.cmp-footer .aem-Grid--12 {
  position: relative;
}

.cmp-footer .cmp-title--paragraph,
.cmp-footer p,
.cmp-footer ul {
  margin: 0;
  padding: 0;
}

.cmp-footer .cmp-title__text {
  margin-bottom: var(--spacing-xs);
}

.cmp-footer .cmp-text a {
  text-decoration: none;
}

.cmp-footer .button {
  text-align: center;
}

.cmp-footer .cmp-button--round {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  right: var(--spacing-xxl);
  box-shadow: var(--box-shadow-md);
}

.cmp-footer .list.list.list.list {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

@include remove-grid-content('.separator');

.cmp-footer .separator {
  margin: var(--spacing-lg) 0;
}

.cmp-footer .separator hr {
  margin: 0;
}

/* Footer - Header */
.cmp-footer .container:first-child .aem-GridColumn {
  padding: var(--spacing-md) 0;
}

.cmp-footer .aem-GridColumn--default--6 ~ .aem-GridColumn--default--6 {
  border-left: 0.125rem solid var(--color-white);
}

/* Footer - Navigation */
.cmp-footer .container:nth-last-child(2) .aem-Grid--12 {
  padding-bottom: var(--spacing-xxl);
  padding-top: var(--spacing-xxl);
}

.cmp-footer .cmp-list__item,
.cmp-footer .cmp-list__item-link {
  color: white;
}

.cmp-footer .cmp-list__item:hover,
.cmp-footer .cmp-button:hover,
.cmp-footer .cmp-list__item-link:hover {
  color: white;
}

/* Footer - Footer */
.cmp-footer .container:last-child .aem-Grid--12 {
  @include regular-md();

  padding-bottom: var(--spacing-xs);
  padding-top: var(--spacing-sm);
}

.cmp-footer .container:last-child .aem-Grid--default--4 {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

@media (--breakpoint-down-sm) {
  /* Footer - Header */
  .cmp-footer .aem-GridColumn--default--6 ~ .aem-GridColumn--default--6 {
    border: none;
    border-top: var(--spacing-xxxs) solid var(--color-white);
  }

  /* Footer - Navigation */
  .cmp-footer .container:nth-last-child(2) .aem-Grid.aem-Grid--12 {
    padding-bottom: var(--spacing-lg);
    padding-top: var(--spacing-lg);
  }

  /* Footer - Footer */
  .cmp-footer .container:last-child .aem-Grid.aem-Grid--12 {
    @include regular-md();

    padding-top: var(--spacing-md);
    padding-bottom: var(--spacing-md);
  }

  .cmp-footer .container:last-child .aem-Grid--default--4 {
    padding-bottom: var(--spacing-sm);
  }

  .cmp-footer .container:last-child .cmp-text {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .cmp-footer .container:last-child .text.aem-GridColumn--default--none:not(:first-child) {
    border-left: var(--spacing-xxxs) solid var(--color-gray-900);
  }
}

@media (--breakpoint-up-lg) {
  footer .cmp-footercmp__button-back-to-top {
    margin-right: auto;
  }

  .cmp-footercmp__button-back-to-top,
  .cmp-footercmp__bottom-media-links,
  .cmp-footercmp__link-columns:not(.cmp-footercmp__social) {
    max-width: var(--page-max-width);
    margin: auto;
  }
}
