table {
  width: 100%;
  text-align: left;
  border: none;
  border-spacing: 0;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  vertical-align: top;
}
