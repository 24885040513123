.cmp-teasers-stage {
  &__image {
    margin-left: var(--grid-anti-margin);
    width: calc(100% + (2 * var(--grid-margin)));
    margin-bottom: calc(var(--spacing-5xl) * -1);
    max-height: toRem(480px);
    overflow: hidden;

    .cmp-responsive-image.replaced img,
    .cmp-responsive-image {
      aspect-ratio: 3/1;

      @media only screen and (--breakpoint-down-md) {
        aspect-ratio: 16/9;
      }
    }

    &:not(.cmp-teasers-stage__image--ultra-wide) {
      .cmp-responsive-image.replaced img,
      .cmp-responsive-image {
        position: relative;
      }
    }

    &--ultra-wide {
      @media (--breakpoint-up-md) {
        height: toRem(480px);

        @include fullWidth;

        .cmp-responsive-image img {
          position: absolute;
          min-width: var(--max-full-width);
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    @media only screen and (--breakpoint-down-md) {
      margin-bottom: calc(var(--spacing-lg) * -1);
    }
  }

  &__bottom-content {
    z-index: 1;
    position: relative;
    overflow-x: hidden;

    @media (--breakpoint-down-md) {
      margin-right: var(--grid-anti-margin);
    }
  }

  &__teasers {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(calc((100% - (4 * var(--spacing-lg))) / 5), 1fr));
    gap: var(--spacing-lg);
    position: relative;
    transition: transform 0.5s ease;
    justify-content: center;

    @media (--breakpoint-down-md) {
      grid-auto-columns: calc((100% / 1.5) - var(--spacing-md));
      justify-content: start;
      gap: var(--spacing-md);
      grid-template-columns: unset; /* Let it be auto-scrolling */
    }
  }

  &__teaser {
    background-color: var(--color-gray-50);
    border-radius: var(--border-radius-m);
    padding: var(--spacing-md);
    flex: 1 1 100%;
    display: flex;
    flex-flow: column;

    &:hover {
      .cmp-teasers-stage__teaser-title {
        color: var(--color-primary-500);
      }

      .cmp-teasers-stage__teaser-button {
        background-color: var(--color-primary-500);
      }
    }

    &-title {
      margin-bottom: var(--spacing-md);
    }

    &-button {
      background-color: var(--color-gray-900);
      color: var(--color-white);
      border-radius: var(--border-radius-xs);
      display: flex;
      margin-left: auto;
      width: fit-content;
      margin-top: auto;

      &::before {
        font-family: 'custom_materialicons', sans-serif;
        content: var(--icon-keyboard_arrow_right);
        font-weight: var(--font-weight-bold);
        font-size: toRem(24px);
        padding-left: var(--spacing-xxxs);
      }
    }

    &-description {
      margin-bottom: var(--spacing-xs);
    }
  }

  &__navigation {
    display: none;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-md);
    margin-top: var(--spacing-lg);

    &-button {
      background-color: var(--color-gray-100);
      color: var(--color-gray-900);
      border-radius: var(--border-radius-xs);
      display: flex;
      width: fit-content;
      font-size: toRem(32px);

      &:hover {
        background-color: var(--color-primary-500);
        color: var(--color-white);
      }

      &:focus,
      &:focus-visible {
        outline: var(--border-m) solid var(--color-focus-500);
      }

      &::before {
        font-family: 'custom_materialicons', sans-serif;
        font-weight: var(--font-weight-bold);
      }

      &--previous {
        &::before {
          content: var(--icon-keyboard_arrow_left);
        }
      }

      &--next {
        &::before {
          content: var(--icon-keyboard_arrow_right);
        }
      }
    }

    @media (--breakpoint-down-md) {
      display: flex;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    margin: var(--spacing-xl) 0 var(--spacing-xxs) 0;

    &-button {
      @include button-secondary;
    }

    @media only screen and (--breakpoint-down-md) {
      margin-top: var(--spacing-lg);
    }
  }
}
