.anchornavigation {
  padding: 0;
  min-height: 0;

  &:not(:has(.cmp-anchor-navigation--sticky)) {
    min-height: toRem(90px);
  }
}

.cmp-anchor-navigation {
  width: 100%;
  background: var(--color-white);
  margin-bottom: var(--spacing-xxxl);
  position: relative;
  z-index: var(--zindex-cmp-anchor-navigation); /* z-index: 4 */
}

.cmp-anchor-navigation__nav {
  overflow-x: auto;
  overflow-y: hidden;
}

@media #{$mq-3} {
  .anchornavigation:not(:has(.cmp-anchor-navigation--sticky)) {
    min-height: toRem(52px);
  }

  .cmp-anchor-navigation {
    margin-bottom: toRem(57px);
  }
}

.cmp-anchor-navigation--sticky {
  left: 0;
  padding: 0 var(--spacing-6xl);
  position: fixed;
  z-index: var(--zindex-cmp-anchor-navigation--sticky); /* z-index: 4 */

  @media #{$mq-mobile} {
    z-index: 10;
  }
}

@media #{$mq-4} {
  .cmp-anchor-navigation--sticky {
    padding: 0 var(--spacing-xxl);
  }
}

@media #{$mq-3}, #{$mq-mobile} {
  .cmp-anchor-navigation--sticky {
    padding: 0 var(--spacing-lg);
  }
}

.cmp-anchor-navigation__list {
  display: flex;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.cmp-anchor-navigation__item--overflowed.cmp-anchor-navigation__list {
  display: table;
}

.cmp-anchor-navigation__item--overflowed .cmp-anchor-navigation__item {
  display: table-cell;
}

.cmp-anchor-navigation__item--overflowed.cmp-anchor-navigation__list-centered {
  justify-content: left;
}

.cmp-anchor-navigation__list.cmp-anchor-navigation__list-centered {
  justify-content: center;
}

.cmp-anchor-navigation__link {
  @include bold-lg();

  display: flex;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-lg);
  white-space: normal;
}

@media #{$mq-desktop} {
  .cmp-anchor-navigation__list {
    justify-content: left;
  }

  .cmp-anchor-navigation--sticky ul {
    box-shadow: 0 var(--spacing-xxxs) 0.1875rem 0 rgb(0 0 0 / 25%);
    justify-content: center;
  }

  .cmp-anchor-navigation__link {
    height: toRem(52px);
  }

  .cmp-anchor-navigation__link:link,
  .cmp-anchor-navigation__link:visited {
    color: var(--color-primary-500);
    border-bottom: var(--spacing-xxs) solid transparent;
  }

  .cmp-anchor-navigation__link:hover,
  .cmp-anchor-navigation__link:active {
    color: var(--color-gray-900);
    border-bottom: var(--spacing-xxs) solid var(--color-gray-900);
  }

  .cmp-anchor-navigation__item--active .cmp-anchor-navigation__link {
    color: var(--color-primary-500);
    border-bottom: var(--spacing-xxs) solid var(--color-primary-500);
  }

  .cmp-anchor-navigation__item--active .cmp-anchor-navigation__link:active {
    color: var(--color-gray-900);
    border-bottom: var(--spacing-xxs) solid var(--color-primary-700);
  }

  [id^='cmp-anchor-navigation__mobile'],
  .cmp-anchor-navigation__toggle,
  .cmp-anchor-navigation__toggle-span {
    display: none;
  }

  .cmp-anchor-navigation__nav::after {
    left: -7.5rem;
    width: calc(100% + (7.5rem * 2));
    overflow: hidden;
    border: var(--spacing-xxxs) solid var(--color-gray-100) 0;
    position: absolute;
    content: '';
    display: block;
    z-index: -1;
    height: 100%;
    top: 0;
    box-shadow: var(--box-shadow);
  }
}

@media #{$mq-mobile} {
  .anchornavigation:not(:has(.cmp-anchor-navigation--sticky)) {
    min-height: toRem(72px);
  }

  .cmp-anchor-navigation {
    margin-bottom: var(--spacing-sm);
  }

  .cmp-anchor-navigation__nav {
    margin-top: unset;
    border-top: var(--spacing-xxxs) solid var(--color-gray-100);
    border-bottom: var(--spacing-xxxs) solid var(--color-gray-100);
    padding: var(--spacing-xs) 0;
  }

  .cmp-anchor-navigation__expanded {
    padding: var(--spacing-xs) 0 var(--spacing-sm) 0;
  }

  .cmp-anchor-navigation__nav::after {
    left: calc(-1 * var(--spacing-xxl));
    width: calc(100% + (var(--spacing-xl) * 2));
    overflow: hidden;
    border-top: var(--spacing-xxxs) solid var(--color-gray-100);
    border-bottom: var(--spacing-xxxs) solid var(--color-gray-100);
    position: absolute;
    content: '';
    display: block;
    top: 0;
    z-index: -1;
    height: 100%;
  }

  .cmp-anchor-navigation__expanded::after {
    box-shadow: var(--box-shadow);
  }

  .cmp-anchor-navigation__list {
    justify-content: left;
  }

  .cmp-anchor-navigation__toggle + a,
  .cmp-anchor-navigation__list {
    display: none;
  }

  .cmp-anchor-navigation__toggle {
    width: var(--spacing-xs);
    height: var(--spacing-xs);
    float: right;
  }

  .cmp-anchor-navigation__toggle-span {
    @include bold-lg();

    color: var(--color-gray-900);
    display: inline-block;
    width: 95%;
  }

  [id^='cmp-anchor-navigation__mobile'] {
    height: var(--spacing-xl);
    width: var(--spacing-xl);
    border: var(--color-white);
    position: absolute;
    right: var(--spacing-xs);
    top: var(--spacing-xs);
  }

  .cmp-anchor-navigation--sticky [id^='cmp-anchor-navigation__mobile'] {
    right: var(--spacing-lg);
  }

  [id^='cmp-anchor-navigation__mobile']:checked + ul {
    display: block;
  }

  [id^='cmp-anchor-navigation__mobile']::after {
    @include regular-3xl();

    font-family: var(--icon-font);
    content: var(--icon-keyboard_arrow_down);
    pointer-events: none;
  }

  [id^='cmp-anchor-navigation__mobile']:checked::after {
    @include regular-3xl();

    font-family: var(--icon-font);
    content: var(--icon-close);
    right: var(--spacing-xxs);
    pointer-events: none;
    position: absolute;
  }

  .cmp-anchor-navigation__expanded.first-active .cmp-anchor-navigation__toggle::after {
    content: ' ';
    border-left: var(--spacing-xxs) solid var(--color-primary-500);
    left: calc(-1 * var(--spacing-md));
    height: var(--spacing-lg);
    position: absolute;
  }

  .cmp-anchor-navigation--sticky .cmp-anchor-navigation__expanded.first-active .cmp-anchor-navigation__toggle::after {
    left: 0;
  }

  .cmp-anchor-navigation__nav .cmp-anchor-navigation__list li {
    display: block;
    width: 100%;
    margin-top: var(--spacing-sm);

    /* stylelint-disable-next-line selector-no-qualifying-type */
    &.cmp-anchor-navigation__item--has-expanded-first-item {
      margin-top: 0;
    }
  }

  .cmp-anchor-navigation__item--active .cmp-anchor-navigation__link::after {
    content: ' ';
    border-left: 0.1875rem solid var(--color-primary-500);
    left: 0;
    height: var(--spacing-lg);
    position: absolute;
  }

  .cmp-anchor-navigation__link:link {
    @include bold-lg();

    padding: 0;
    color: var(--color-gray-900);
  }

  body:has(.cmp-header__dropdown--open) .cmp-anchor-navigation--sticky,
  body:has(.cmp-header.cmp-header--menu-open) .cmp-anchor-navigation--sticky {
    z-index: unset;
  }
}

.cmp-anchor-navigation__nav::after {
  @media #{$mq-4, $mq-3} {
    width: calc(100% + (var(--grid-margin) * 2));
    left: var(--grid-anti-margin);
  }
}

@media #{$mq-6} {
  .cmp-anchor-navigation__nav::after {
    @include fullWidthBackgroundColor($position: absolute);
  }
}

.cmp-anchor-navigation__toggle-span--is-hidden {
  display: none;
}

.pagestage {
  &:not(:has([data-dm='breadcrumb.component'])) {
    > header {
      margin-bottom: var(--spacing-xxxl);

      @media #{$mq-mobile} {
        margin-bottom: var(--spacing-sm);
      }
    }

    .anchornavigation .cmp-anchor-navigation {
      margin-top: calc(-1 * var(--spacing-xxxl));

      @media #{$mq-mobile} {
        margin-top: calc(-1 * var(--spacing-sm));
      }
    }

    .anchornavigation .cmp-anchor-navigation.cmp-anchor-navigation--sticky {
      margin-top: 0;
    }
  }
}
