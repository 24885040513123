.marquee-container .aem-Grid.aem-Grid--12.aem-Grid--default--12,
.marquee-container .aem-GridColumn {
  padding: 0;
  margin: 0;
}

.cmp-marquee {
  position: relative;
  padding: toRem(21px) var(--spacing-xxl);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: toRem(64px);

  @media only screen and (--breakpoint-down-md) {
    padding: toRem(21px) toRem(28px);
    flex-direction: column;
    gap: var(--spacing-lg);
    align-items: flex-start;
    margin-top: toRem(32px);
  }

  &__title {
    color: var(--color-white);
    margin-bottom: var(--spacing-md);
  }

  &__description {
    color: var(--color-white);
  }

  &__button {
    @include bold-lg();
  }

  &__content {
    max-width: 80%;
  }

  &::after {
    background-image: url('/src/main/webpack/resources/marquee-background.png');
    background-position: center;
    background-size: cover;
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    height: 100%;
    left: 0;
    width: 100%;
    bottom: 0;
    border-radius: var(--border-radius-m);

    @media only screen and (--breakpoint-down-md) {
      border-radius: 0;
    }
  }

  .cmp-button--secondary-positive {
    margin-left: auto;

    @media only screen and (--breakpoint-down-md) {
      margin-left: initial;
    }
  }
}
