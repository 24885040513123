.c-link {
  color: var(--color-primary-500);
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  transition: transform 0.1s;

  &__icon {
    display: inline-flex;
    vertical-align: middle;
  }

  &__text {
    font-weight: var(--font-weight-bold);
  }

  &:hover {
    color: var(--color-gray-900);

    .c-link__text {
      text-decoration: underline;
    }
  }
}

.c-link__icon + .c-link__text {
  margin-left: var(--spacing-xxs);
}

.c-link__text + .c-link__icon {
  margin-left: var(--spacing-xs);
}
