.cmp-facts-and-figures {
  &__top-content {
    position: relative;
    display: flex;
    flex-flow: column;
    padding: var(--spacing-5xl) 20% 10rem;
    color: var(--color-white);
    margin: 0 var(--grid-anti-margin);
    align-items: center;
    margin-bottom: calc(var(--spacing-5xl) * -1);
    text-align: center;

    @media only screen and (--breakpoint-down-md) {
      padding: var(--spacing-xl) var(--spacing-md) var(--spacing-xxxl) var(--spacing-md);
      margin-bottom: calc(var(--spacing-lg) * -1);
    }

    &::before {
      background-image: url('/src/main/webpack/resources/marquee-background.png');
      background-position: center;
      background-size: cover;
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      height: 100%;
      bottom: 0;
      width: 100%;
    }

    &--ultra-wide {
      &::before {
        @media (--breakpoint-up-lg) {
          width: calc(100vw - var(--spacing-xs));
          left: calc(50% - 50vw);
        }

        @media (--breakpoint-up-xxl) {
          width: var(--max-full-width);
          left: auto;
        }
      }
    }
  }

  &__bottom-content {
    z-index: 1;
    position: relative;
    overflow-x: hidden;
  }

  &__blocks {
    display: grid;
    gap: var(--spacing-lg);
    position: relative;
    transition: margin-left 0.3s ease-in-out;
  }

  &__block {
    background-color: var(--color-gray-50);
    border-radius: var(--border-radius-m);
    padding: var(--spacing-xxl) var(--spacing-md);
    display: flex;
    flex-flow: column;
    align-items: center;
    border-style: var(--border-xs) solid var(--color-gray-300);
  }

  &__block-number {
    &--initialised {
      opacity: 1;
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-md);
    margin-top: var(--spacing-lg);

    &-button {
      background-color: var(--color-gray-100);
      color: var(--color-gray-900);
      border-radius: var(--border-radius-xs);
      display: flex;
      width: fit-content;
      font-size: toRem(32px);

      &:hover {
        background-color: var(--color-primary-500);
        color: var(--color-white);
      }

      &:focus,
      &:focus-visible {
        outline: var(--border-m) solid var(--color-focus-500);
      }

      &::before {
        font-family: 'custom_materialicons', sans-serif;
        font-weight: var(--font-weight-bold);
      }

      &--previous {
        &::before {
          content: var(--icon-keyboard_arrow_left);
        }
      }

      &--next {
        &::before {
          content: var(--icon-keyboard_arrow_right);
        }
      }
    }

    &-block-counter {
      @include regular-md();
    }
  }

  &--horizontal {
    .cmp-facts-and-figures__bottom-content {
      @media (--breakpoint-down-md) {
        margin-right: var(--grid-anti-margin);
      }
    }

    .cmp-facts-and-figures__blocks {
      display: grid;
      grid-auto-flow: column;
      gap: var(--spacing-lg);
      transition: transform 0.5s ease;
      box-sizing: border-box;

      @media (--breakpoint-down-md) {
        gap: var(--spacing-md);
      }
    }

    .cmp-facts-and-figures__block {
      &-description {
        @include bold-lg();

        text-align: center;
      }
    }

    .cmp-facts-and-figures__blocks--4 {
      grid-auto-columns: calc((100% - (3 * var(--spacing-lg))) / 4);

      .cmp-facts-and-figures__block {
        min-width: calc((100% / 4) - var(--spacing-lg));
      }

      @media (--breakpoint-down-md) {
        grid-auto-columns: calc((100% / 1.5) - var(--spacing-md));
      }
    }

    .cmp-facts-and-figures__blocks--3 {
      grid-auto-columns: calc((100% - (2 * var(--spacing-lg))) / 3);

      .cmp-facts-and-figures__block {
        min-width: calc((100% / 3) - var(--spacing-lg));
      }

      @media (--breakpoint-down-md) {
        grid-auto-columns: calc((100% / 1.5) - var(--spacing-md));
      }
    }
  }

  &--vertical {
    .cmp-facts-and-figures__blocks--3 {
      grid-template-columns: repeat(3, minmax(0, 1fr));

      @media (--breakpoint-down-md) {
        grid-template-columns: 1fr;
      }
    }

    .cmp-facts-and-figures__blocks--4 {
      grid-template-columns: repeat(auto-fit, minmax(calc((100% - (3 * var(--spacing-lg))) / 4), 1fr));

      @media (--breakpoint-down-md) {
        grid-template-columns: 1fr;
      }
    }
  }
}
