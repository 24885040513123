/* custom select */
.c-custom-select-opener {
  align-items: center;
  background-color: var(--color-white);
  display: flex;
  justify-content: space-between;
  height: 2.5rem; /* 40 */
  outline: none;
  padding: 0 var(--spacing-sm);
}

.c-custom-select-opener::after {
  font-family: 'custom_materialicons', sans-serif;
  font-size: var(--font-size-icon-md);
  content: var(--icon-keyboard_arrow_down);
}

.is-open.c-custom-select-container .c-custom-select-opener::after {
  transform: rotate(180deg);
}

.c-custom-select-panel {
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-md);
  display: none;
  left: 0;
  max-height: 25rem; /* 400 */
  overflow: auto;
  position: absolute;
  right: 0;
  top: 100%;
  white-space: nowrap;
}

.is-open .c-custom-select-panel {
  display: block;
}

.is-open {
  z-index: 3;
}

.c-custom-select-option {
  height: 2.5rem; /* 40 */
  line-height: var(--line-height-xl);
  padding: 0 var(--spacing-lg);
}

.c-custom-select-option.is-selected {
  background-color: var(--color-gray-50);
}

.c-custom-select-option:hover,
.c-custom-select-option.has-focus {
  background-color: var(--color-gray-100);
}

.cmp-form-options label + label {
  margin-top: var(--spacing-md);
}

/* optgroup */
.c-custom-select-optgroup {
  cursor: pointer;
}

.c-custom-select-optgroup .has-focus {
  background-color: var(--color-gray-100);
}

.c-custom-select-arrow {
  cursor: pointer;
  display: inline-block;
}

.c-custom-select-arrow::before {
  font-weight: var(--font-weight-bold);
}

.c-custom-select-arrow--down::before {
  transform: rotate(90deg);
}

.c-custom-select-optgroup.is-hidden {
  display: none;
}

.c-custom-select-option.last-item-group::before {
  content: '     ';
  white-space: pre;
}

/* validation */
.cmp-form .c-custom-select-container.is-empty {
  border: var(--spacing-xxxs) solid var(--color-primary-500);
  margin-bottom: 1.563rem;
}

.cmp-form .c-custom-select-container .cmp-form__error-message {
  display: none;
}

.cmp-form .c-custom-select-container.is-empty .cmp-form__error-message {
  display: block;
  position: absolute;
  bottom: -1.563rem;
  z-index: -1;
}

.cmp-form .c-custom-select-container.is-empty::before {
  top: var(--spacing-xs);
  right: var(--spacing-xxxl);
}

.cmp-form .c-custom-select-container.is-empty::after {
  font-family: 'custom_materialicons', sans-serif;
  font-size: var(--font-size-icon-md);
  content: var(--icon-keyboard_arrow_down);
  color: inherit;
  position: absolute;
  top: .325rem;
  right: var(--spacing-sm);
  background-color: unset;
  left: unset;
}

.c-custom-select-container.is-empty .c-custom-select-opener::after {
  display: none;
}
