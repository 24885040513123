.cmp-footercmp {
  color: var(--color-white);
  position: relative;

  .cmp-footercmp__rich-text--copyrightsLink {
    .cmp-text {
      font-weight: var(--font-weight-bold);

      @media (--breakpoint-up-md) {
        margin-bottom: toRem(10px);
      }
    }
  }

  .cmp-footercmp__link-columns-social {
    max-width: var(--page-max-width);
    width: 100%;
    margin: auto;
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    padding-top: var(--spacing-xs);
    padding-bottom: var(--spacing-xxl);

    @media (--breakpoint-down-lg) {
      padding-left: var(--spacing-xxl);
      padding-right: var(--spacing-xxl);
    }

    @media (--breakpoint-down-md) {
      padding-top: var(--spacing-lg);
      padding-bottom: 0;
    }

    @media (--breakpoint-down-sm) {
      padding-left: var(--spacing-md);
      padding-right: var(--spacing-md);
    }
  }

  .cmp-list__item-link {
    align-items: flex-end;
    display: inline-table;

    &::before {
      content: none;
    }

    &::after {
      font-family: "custom_materialicons", sans-serif;
      content: var(--icon-keyboard_arrow_right);
      font-weight: var(--font-weight-bold);
      margin-left: var(--spacing-xxs);
      vertical-align: middle;
      line-height: var(--line-height-sm);
      font-size: var(--font-size-icon-sm);
    }
  }

  .cmp-list__item[aria-level="2"] {
    .cmp-list__item-link {
      font-weight: normal;
      margin-left: var(--spacing-lg);
    }
  }

  .cmp-footercmp__legal-links {
    @include regular-md();

    color: var(--color-gray-900);
    display: flex;

    li {
      list-style: none;

      @media (--breakpoint-up-md) {
        .cmp-text {
          margin-left: var(--spacing-lg);
        }
      }

      .cmp-text {
        &:hover {
          text-decoration: underline;
          color: var(--color-primary-700);
        }
      }
    }
  }

  .cmp-footercmp__bottom {
    &-media-links {
      align-items: flex-end;

      @media (--breakpoint-down-sm) {
        align-items: center;
      }
    }
  }

  .cmp-footercmp__global-link {
    color: var(--color-gray-900);
    padding: var(--spacing-xs);
    justify-content: flex-start;
    border-left: var(--border-xl) solid var(--color-primary-500);
    border-radius: 0;

    &::after {
      font-family: 'custom_materialicons', sans-serif;
      content: var(--icon-keyboard_arrow_right);
      font-weight: var(--font-weight-bold);
      margin-left: var(--spacing-xs);
    }
  }

  .cmp-footercmp__bottom-copyright-wrapper {
    @media (--breakpoint-down-md) {
      width: 100%;
      max-width: toRem(250px);
      margin-bottom: var(--spacing-md);
    }
  }
}

.cmp-footercmp p,
.cmp-footercmp ul {
  margin: 0;
  padding: 0;
}

.cmp-footercmp--background-primary {
  background-color: var(--color-primary-500);
}

.cmp-footercmp--background-neutral-lighter {
  background-color: var(--color-gray-50);
}

.cmp-footercmp--background-neutral-light {
  background-color: var(--color-gray-100);
}

.cmp-footercmp--background-neutral-dark {
  background-color: var(--color-gray-500);
}

.cmp-footercmp .cmp-footercmp__title-text {
  @include bold-lg();

  margin-bottom: var(--spacing-xs);
}

.cmp-footercmp .cmp-text a {
  text-decoration: none;
  color: var(--color-gray-900);
}

.cmp-footercmp .button {
  text-align: center;
}

.cmp-footercmp .separator {
  margin: var(--spacing-lg) 0;
}

.cmp-footercmp .separator hr {
  margin: 0;
}

.cmp-footercmp .cmp-footercmp__button {
  background-color: transparent;
  border: none;
  color: inherit;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  align-items: center;
}

/* General for Web,
 and PowerTv */

.cmp-footercmp.cmp-footercmp--web .cmp-button--round,
.cmp-footercmp.cmp-footercmp--intranet .cmp-button--round,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-button--round {
  position: unset;
  transform: none;
  box-shadow: none;
  color: var(--color-white);
  background-color: var(--color-primary-500);
  border-radius: 0;

  &:has(.cmp-footercmp__button:hover) {
    background-color: var(--color-black);
  }

  .cmp-footercmp__button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow);
    height: 100%;
    width: 100%;
    padding: var(--spacing-xxs);
  }
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__contact-button .cmp-button__icon {
  margin-right: var(--spacing-md);
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__button:hover .cmp-footercmp__button-text {
  text-decoration: underline;
  color: var(--color-white);
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__social-icon,
.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__social-icon .cmp-footercmp__button {
  color: var(--color-gray-900);
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__social-icon,
.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__social-icon,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__social-icon {
  margin: 0 0.313rem;
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__social-icon:first-child,
.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__social-icon:first-child,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__social-icon:first-child {
  margin-left: 0;
}

.cmp-footercmp__button-back-to-top {
  display: flex;
  justify-content: flex-end;
  margin-right: var(--spacing-4xl);
  margin-top: var(--spacing-xl);

  .cmp-button__icon--back-to-top::before {
    font-size: var(--font-size-h1);
  }

  .cmp-button__icon {
    margin-top: 0;
  }
}

.cmp-footercmp__link-columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: self-start;
  column-gap: var(--spacing-xl);

  .cmp-footercmp__global-link {
    grid-column: span 4;
    margin-left: calc(var(--spacing-md) * -1);

    &:hover {
      color: var(--color-primary-500);
    }

    @media (--breakpoint-up-md) {
      margin-bottom: var(--spacing-lg);
    }

    @media (--breakpoint-down-md) {
      display: none;
    }
  }

  &.cmp-footercmp__social {
    display: flex;
    padding: 0;
  }

  @media (--breakpoint-down-md) {
    grid-template-columns: repeat(2, 1fr);
    padding-top: var(--spacing-lg);
    row-gap: var(--spacing-lg);

    .cmp-footercmp__global-link {
      grid-column: span 2;
    }

    .cmp-footercmp__link-columns-social {
      flex-direction: column;
      gap: var(--spacing-lg);
    }
  }

  @media (--breakpoint-down-lg) {
    padding: 0 var(--spacing-xxl) var(--spacing-xxxl);
  }

  @media (--breakpoint-down-md) {
    padding-top: var(--spacing-lg);
  }

  @media (--breakpoint-up-md) {
    padding-top: var(--spacing-lg);
    padding-bottom: var(--spacing-xxxl);
  }

  &:not(.cmp-footercmp__link-columns-top) {
    background: var(--color-gray-100);

    @media (--breakpoint-down-md) {
      flex-direction: column;
      gap: var(--spacing-lg);

      .cmp-footercmp__legal-links {
        flex-direction: column;
        gap: var(--spacing-md);
      }
    }
  }
}

.cmp-footercmp__link-columns-separator {
  display: none;
}

.cmp-footercmp__bottom-media-links {
  @include regular-md();

  display: flex;
  justify-content: space-between;
  padding: var(--spacing-xs) 0;

  @media (--breakpoint-down-lg) {
    padding-left: var(--spacing-xxl);
    padding-right: var(--spacing-xxl);
  }
}

.cmp-footercmp__bottom-links {
  display: flex;
  align-items: self-start;
}

.cmp-footercmp .cmp-footercmp__rich-text-wrapper a:hover {
  text-decoration: underline;
}

/* CMP-FOOTER WEB */

.cmp-footercmp.cmp-footercmp--web .cmp-list__item-link,
.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__title,
.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links .cmp-text a {
  color: var(--color-gray-900);
}

.cmp-footercmp.cmp-footercmp--web .cmp-list__item:hover,
.cmp-footercmp.cmp-footercmp--web .cmp-list__item-link:hover {
  color: var(--color-primary-700);
}

.cmp-footercmp.cmp-footercmp--web .cmp-list__item:active,
.cmp-footercmp.cmp-footercmp--web .cmp-list__item-link:active {
  color: var(--color-primary-800);
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links {
  list-style: none;
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links .cmp-text:hover,
.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__rich-text--copyrightsLink .cmp-text a:hover {
  text-decoration: underline;
  color: var(--color-primary-700);
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links .text:last-child .cmp-text {
  margin-right: 0;
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__contact-button {
  height: 3.188rem;
  display: flex;
  align-items: center;
}

.cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom .text--mobile {
  margin-bottom: 1.563rem;
}

/* INTRANET AND POWER TV */

.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__contact-button,
.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__link-columns-wrapper .cmp-footercmp__title-text,
.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__bottom-links,
.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__bottom .cmp-footercmp__rich-text--copyrightsLink,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__contact-button,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__link-columns-wrapper .cmp-footercmp__title-text,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__bottom-links,
.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__bottom .cmp-footercmp__rich-text--copyrightsLink,
.cmp-footercmp__mobile {
  display: none;
}

.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__link-columns,
.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__link-columns {
  background: inherit;
}

.cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__link-columns-social,
.cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__link-columns-social {
  padding: 0;

  @media (--breakpoint-up-md) {
    grid-column: 4;
  }
}

@media (--breakpoint-up-md) {
  .cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links .cmp-text {
    margin-left: var(--spacing-xxl);
  }
}

@media (--breakpoint-down-md) {
  .cmp-footercmp .cmp-footercmp__bottom-media-links {
    @include regular-sm();

    flex-direction: column;
    text-align: center;
    padding: var(--spacing-lg) var(--spacing-md);
    align-items: center;
  }

  .cmp-footercmp__desktop {
    display: none;
  }

  .cmp-footercmp__bottom .cmp-footercmp__rich-text--copyrightsLink {
    border: var(--spacing-xxxs) solid #2d2d2d;
    padding: 0.625rem 0;
  }

  .cmp-footercmp__mobile {
    display: block;
  }

  .cmp-footercmp__bottom .cmp-footercmp__rich-text--copyrights {
    margin-top: var(--spacing-lg);
  }
}

@media (--breakpoint-up-sm) {
  .cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links .cmp-text {
    margin-left: var(--spacing-md);
    white-space: nowrap;
  }
}

@media (--breakpoint-down-sm) {
  .cmp-footercmp__link-columns {
    padding: var(--spacing-lg) var(--spacing-md) 1.875rem;
    flex-wrap: wrap;
  }

  .cmp-footercmp__link-columns-left,
  .cmp-footercmp__link-columns-middle {
    flex: 0 0 50%;
    margin: 0;
  }

  .cmp-footercmp__social-icons-wrapper {
    flex: 0 0 100%;
  }

  .cmp-footercmp__legal-links {
    color: var(--color-gray-900);

    li {
      list-style: none;

      .cmp-text {
        margin-right: var(--spacing-xxl);
      }
    }
  }

  .cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom {
    position: relative;
  }

  .cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links {
    @include regular-sm();

    justify-content: center;
    flex-wrap: wrap;
    margin: var(--spacing-sm) 0;
    z-index: 2;
  }

  .cmp-footercmp__bottom .cmp-footercmp__rich-text--copyrights {
    line-height: var(--sm-line-height-sm);

    .cmp-text {
      @include regular-sm();
    }
  }

  .cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links .cmp-text {
    margin-right: 0;
    padding: 0 0.313rem;
    border-right: var(--spacing-xxxs) solid var(--color-gray-300);
  }

  .cmp-footercmp.cmp-footercmp--web .cmp-footercmp__bottom-links li:last-child .cmp-text {
    border: 0;
  }

  .cmp-footercmp__button-back-to-top {
    margin-right: var(--spacing-xl);
    margin-top: var(--spacing-md);
  }

  .cmp-footercmp.cmp-footercmp--power-tv .cmp-footercmp__social-icon-list,
  .cmp-footercmp.cmp-footercmp--intranet .cmp-footercmp__social-icon-list {
    display: flex;
    justify-content: center;
  }
}
